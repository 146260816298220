<template>
	<c-dialog ref="dialog" :title="'订单：' + orderNumber + ' - 发货明细'" width="800" :button="false">
		<c-table ref="table" height="130" :paging="false"  @rowClick="loadPackDetail">
			<c-table-column
				label="发货仓"
				width="100"
				name="warehouse_name"
			>
			</c-table-column>
			<c-table-column
				label="发货人"
				width="100"
				name="user_realname"
			>
			</c-table-column>
			<c-table-column
				label="发货数量"
				width="100"
				name="product_count"
			>
			</c-table-column>
			<c-table-column
				label="发货时间"
				width="140"
				name="create_date"
			>
			</c-table-column>
		</c-table>
		<c-table ref="detail_table" height="400" :paging="false">
			<c-table-column
				label="产品颜色"
				width="100"
				name="product_color"
				v-if="systemConfig.system_size_enable=='ON'"
				:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.product_color == data.product_color){
								span_count ++;
							}
						});
						return span_count;
				}"
			>
			</c-table-column>
			<c-table-column
				label="产品颜色"
				width="100"
				name="product_spec"
				:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
								span_count ++;
							}
						});
						return span_count;
				}"
			>
			</c-table-column>
			<c-table-column
				label="产品尺码"
				width="80"
				name="product_size"
				v-if="systemConfig.system_size_enable=='ON'"
			>
			</c-table-column>
			<c-table-column
				label="发货数量"
				width="80"
				name="product_count"
			>
			</c-table-column>
		</c-table>
	</c-dialog>
</template>

<script>
	import {mapState} from 'vuex'
	export default {
		computed: {
			...mapState(['systemConfig']),
		},
		
		data() {
			return {
				orderNumber: ''
			}
		},
		
		methods: {
			open(data) {
				this.orderNumber = data.order_number;
				this._id = data.id;
				this.$refs.dialog.open();
				this.$refs.table.load({
					url: '/order/deliver/pack',
					data: {
						order_number: data.order_number,
					}
				});
				//清空明细表数据
				this.$refs.detail_table.store.setData([])
			},
			//加载发货明细
			loadPackDetail(row) {
				this.$refs.detail_table.load({
					url: '/order/deliver/pack/detail',
					data: {
						pack_id: row.data.id,
					}
				});
			}
		}
	}
</script>