var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-dialog',{ref:"dialog",attrs:{"title":'订单：' + _vm.orderNumber + ' - 发货明细',"width":"800","button":false}},[_c('c-table',{ref:"table",attrs:{"height":"130","paging":false},on:{"rowClick":_vm.loadPackDetail}},[_c('c-table-column',{attrs:{"label":"发货仓","width":"100","name":"warehouse_name"}}),_c('c-table-column',{attrs:{"label":"发货人","width":"100","name":"user_realname"}}),_c('c-table-column',{attrs:{"label":"发货数量","width":"100","name":"product_count"}}),_c('c-table-column',{attrs:{"label":"发货时间","width":"140","name":"create_date"}})],1),_c('c-table',{ref:"detail_table",attrs:{"height":"400","paging":false}},[(_vm.systemConfig.system_size_enable=='ON')?_c('c-table-column',{attrs:{"label":"产品颜色","width":"100","name":"product_color","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.product_color == data.product_color){
							span_count ++;
						}
					});
					return span_count;
			}}}):_vm._e(),_c('c-table-column',{attrs:{"label":"产品颜色","width":"100","name":"product_spec","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.product_color == data.product_color && item.data.product_spec == data.product_spec){
							span_count ++;
						}
					});
					return span_count;
			}}}),(_vm.systemConfig.system_size_enable=='ON')?_c('c-table-column',{attrs:{"label":"产品尺码","width":"80","name":"product_size"}}):_vm._e(),_c('c-table-column',{attrs:{"label":"发货数量","width":"80","name":"product_count"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }