<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="订单号">
					<c-input name="order_number"></c-input>
				</m-search-item>
				<m-search-item label="客户名称">
					<c-input name="customer_name"></c-input>
				</m-search-item>
				<m-search-item label="产品">
					<c-input name="product_name"></c-input>
				</m-search-item>
				<m-search-item label="客户货号">
					<c-input name="customer_product_code"></c-input>
				</m-search-item>
				<m-search-item label="产品颜色">
					<c-input name="product_color" v-if="systemConfig.system_size_enable=='ON'"></c-input>
				</m-search-item>
				<m-search-item label="订单状态">
					<c-select name="state" :options="orderState"></c-select>
				</m-search-item>
				<m-search-item label="派工状态">
					<c-select name="task_state" :options="taskState"></c-select>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button v-if="auth('m_create_order')" color="sys" @click="navigateTo('order/create')">创建订单</c-button>
			</m-operate>
			
			<c-table ref="table" height="grow">
				<c-table-column
					label="订单号"
					name="order_number"
					width="100"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.order_number == data.order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<c-table-column
					label="订单状态"
					width="80"
					name="state"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.order_number == data.order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span v-if="data.state == -2" style="color:darkgray">草稿</span>
						<span v-if="data.state == -1" style="color:red">待派工</span>
						<span v-if="data.state == 1" style="color:blue">待发货</span>
						<span v-if="data.state == 2" style="color:brown">部分发货</span>
						<span v-if="data.state == 3" style="color:green">发货完成</span>
						<span v-if="data.state == 4" style="color:yellow">已特结</span>
					</template>
				</c-table-column>
				<c-table-column
					label="派工状态"
					width="80"
					name="task_state"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.order_number == data.order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span v-if="data.state == -2">--</span>
						<span v-if="data.state >0 && data.task_state == 1" style="color:blue">待派工</span>
						<span v-if="data.state >0 && data.task_state == 2" style="color:brown">部分派工</span>
						<span v-if="data.state >0 && data.task_state == 3" style="color:green">派工完成</span>
					</template>
				</c-table-column>
				<c-table-column
					label="客户名称"
					name="customer_name"
					width="120"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.order_number == data.order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<c-table-column
					label="产品"
					name="product_name"
					width="180"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.order_number == data.order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span>{{data.product_name}}</span>
						<span v-if="data.product_code">【{{data.product_code}}】</span>
					</template>
				</c-table-column>
				<c-table-column
					label="产品颜色"
					name="product_spec"
					width="120"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.order_number == data.order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span>{{data.product_spec}}</span>
					</template>
				</c-table-column>
				<c-table-column
					label="客户货号"
					name="customer_product_code"
					width="100"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.order_number == data.order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span v-if="data.customer_product_code">{{data.customer_product_code}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>
				<c-table-column
					label="产品颜色"
					name="product_color"
					width="100"
					v-if="systemConfig.system_size_enable=='ON'"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.order_number == data.order_number && item.data.product_color == data.product_color){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<template v-for="(size, i) in sizeConfig">
					<c-table-column
						:label="String(i+1)"
						width="50"
						v-if="systemConfig.system_size_enable=='ON'"
					>
						<template #default="{data}">
							<span v-if="data[size]">{{data[size]}}</span>
							<span v-else>-</span>
						</template>
					</c-table-column>
				</template>
				<c-table-column
					label="商品总数"
					name="product_count"
					width="80"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.order_number == data.order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				></c-table-column>
				<c-table-column
					label="订单金额"
					name="order_money"
					width="90"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.order_number == data.order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<span v-if="data.order_money">¥{{data.order_money}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>
				<c-table-column
					label="交货日期"
					name="delivery_schedule_date"
					width="100"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.order_number == data.order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<c-table-column
					label="下单时间"
					name="create_date"
					width="140"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.order_number == data.order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
				</c-table-column>
				<c-table-column
					type="button"
					label="操作"
					width="230"
					:row-span="({data},list)=>{
						var span_count = 0;
						list.forEach(item => {
							if(item.data.order_number == data.order_number){
								span_count ++;
							}
						});
						return span_count;
					}"
				>
					<template #default="{data}">
						<c-table-button v-if="auth('m_order')"  @click="navigateTo(`/order/detail/${data.id}`)">查看</c-table-button>
						<c-table-button v-if="auth('m_create_order') && data.state==-2" @click="navigateTo(`/order/edit/${data.id}`)">编辑</c-table-button>
						<c-table-button v-if="auth('m_create_order') && data.state==-2"  @click="del(data)">删除</c-table-button>
						<c-table-button v-if="auth('b_order_transfer') && data.state > 0 && (data.task_state==1 || data.task_state==2)" @click="navigateTo(`/order/task/${data.id}`)">派工</c-table-button>
						<c-table-button v-if="auth('b_order_deliver') && (data.state==1 || data.state==2)" @click="$refs.deliverDialog.open(data)">发货</c-table-button>
						<c-table-button v-if="auth('b_order_deliver') && (data.state==2 || data.state==3)" @click="$refs.deliverDetailDialog.open(data)">发货明细</c-table-button>
					</template>
				</c-table-column>
			</c-table>
			<!--发货弹窗-->
			<order-deliver ref="deliverDialog" @resolve="shuaxin()"></order-deliver>
			<!--发货明细弹窗-->
			<order-deliver-detail ref="deliverDetailDialog"></order-deliver-detail>
		</module>
	</page>
</template>

<script>
	import orderDeliver from './order_deliver.vue'
	import orderDeliverDetail from './order_deliver_detail.vue'
    import {mapState} from 'vuex'
	export default {
		name: 'm_order',
		
		components: {
			orderDeliver,orderDeliverDetail
		},
		
		data() {
			return {
				orderState: [{
					name: '草稿',
					value: "-2"
				},{
					name: '待发货',
					value: "1"
				},{
					name: '部分发货',
					value: "2"
				},{
					name: '发货完成',
					value: "3"
				},{
					name: '已特结发货',
					value: "4"
				}],
				taskState: [{
					name: '待派工',
					value: "1"
				},{
					name: '部分派工',
					value: "2"
				},{
					name: '派工完成',
					value: "3"
				}]
			}
		},
		
		computed: {
			...mapState(['sizeConfig','systemConfig'])
		},
		
		mounted() {
			setTimeout(() => {
				this.$refs.search.search();
			});
			
			this.$bus.$on('orderList.update', data => {
				// 刷新列表
				this.$refs.table.update();
			});
		},
		
		methods: {
			//搜索
			searchHandle(data) {
				this.$refs.table.load({
					url: '/order/list',
					data
				});
			},
			//删除订单
			del(data){
				this.$confirm({
					message: '确定要删除订单【'+data.order_number+'】吗？',
					resolve: () => {
						this.request({
							url: 'order/remove',
							data: {id: data.id,},
							loading: true,
							success: data => {
								this.$message({
									type: 'success',
									message: '删除成功'
								});
								this.$refs.table.update();
							}
						});
					}
				});
			},
			//刷新列表
			shuaxin(){
				this.$refs.table.update();
			}
		}
	}
</script>