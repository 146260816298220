var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page',[_c('module',{attrs:{"enable-flex":""}},[_c('m-search',{ref:"search",on:{"search":_vm.searchHandle}},[_c('m-search-item',{attrs:{"label":"订单号"}},[_c('c-input',{attrs:{"name":"order_number"}})],1),_c('m-search-item',{attrs:{"label":"客户名称"}},[_c('c-input',{attrs:{"name":"customer_name"}})],1),_c('m-search-item',{attrs:{"label":"产品"}},[_c('c-input',{attrs:{"name":"product_name"}})],1),_c('m-search-item',{attrs:{"label":"客户货号"}},[_c('c-input',{attrs:{"name":"customer_product_code"}})],1),_c('m-search-item',{attrs:{"label":"产品颜色"}},[(_vm.systemConfig.system_size_enable=='ON')?_c('c-input',{attrs:{"name":"product_color"}}):_vm._e()],1),_c('m-search-item',{attrs:{"label":"订单状态"}},[_c('c-select',{attrs:{"name":"state","options":_vm.orderState}})],1),_c('m-search-item',{attrs:{"label":"派工状态"}},[_c('c-select',{attrs:{"name":"task_state","options":_vm.taskState}})],1)],1),_c('m-operate',[(_vm.auth('m_create_order'))?_c('c-button',{attrs:{"color":"sys"},on:{"click":function($event){return _vm.navigateTo('order/create')}}},[_vm._v("创建订单")]):_vm._e()],1),_c('c-table',{ref:"table",attrs:{"height":"grow"}},[_c('c-table-column',{attrs:{"label":"订单号","name":"order_number","width":"100","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.order_number == data.order_number){
							span_count ++;
						}
					});
					return span_count;
				}}}),_c('c-table-column',{attrs:{"label":"订单状态","width":"80","name":"state","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.order_number == data.order_number){
							span_count ++;
						}
					});
					return span_count;
				}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [(data.state == -2)?_c('span',{staticStyle:{"color":"darkgray"}},[_vm._v("草稿")]):_vm._e(),(data.state == -1)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("待派工")]):_vm._e(),(data.state == 1)?_c('span',{staticStyle:{"color":"blue"}},[_vm._v("待发货")]):_vm._e(),(data.state == 2)?_c('span',{staticStyle:{"color":"brown"}},[_vm._v("部分发货")]):_vm._e(),(data.state == 3)?_c('span',{staticStyle:{"color":"green"}},[_vm._v("发货完成")]):_vm._e(),(data.state == 4)?_c('span',{staticStyle:{"color":"yellow"}},[_vm._v("已特结")]):_vm._e()]}}])}),_c('c-table-column',{attrs:{"label":"派工状态","width":"80","name":"task_state","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.order_number == data.order_number){
							span_count ++;
						}
					});
					return span_count;
				}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [(data.state == -2)?_c('span',[_vm._v("--")]):_vm._e(),(data.state >0 && data.task_state == 1)?_c('span',{staticStyle:{"color":"blue"}},[_vm._v("待派工")]):_vm._e(),(data.state >0 && data.task_state == 2)?_c('span',{staticStyle:{"color":"brown"}},[_vm._v("部分派工")]):_vm._e(),(data.state >0 && data.task_state == 3)?_c('span',{staticStyle:{"color":"green"}},[_vm._v("派工完成")]):_vm._e()]}}])}),_c('c-table-column',{attrs:{"label":"客户名称","name":"customer_name","width":"120","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.order_number == data.order_number){
							span_count ++;
						}
					});
					return span_count;
				}}}),_c('c-table-column',{attrs:{"label":"产品","name":"product_name","width":"180","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.order_number == data.order_number){
							span_count ++;
						}
					});
					return span_count;
				}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [_c('span',[_vm._v(_vm._s(data.product_name))]),(data.product_code)?_c('span',[_vm._v("【"+_vm._s(data.product_code)+"】")]):_vm._e()]}}])}),_c('c-table-column',{attrs:{"label":"产品颜色","name":"product_spec","width":"120","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.order_number == data.order_number){
							span_count ++;
						}
					});
					return span_count;
				}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [_c('span',[_vm._v(_vm._s(data.product_spec))])]}}])}),_c('c-table-column',{attrs:{"label":"客户货号","name":"customer_product_code","width":"100","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.order_number == data.order_number){
							span_count ++;
						}
					});
					return span_count;
				}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [(data.customer_product_code)?_c('span',[_vm._v(_vm._s(data.customer_product_code))]):_c('span',[_vm._v("--")])]}}])}),(_vm.systemConfig.system_size_enable=='ON')?_c('c-table-column',{attrs:{"label":"产品颜色","name":"product_color","width":"100","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.order_number == data.order_number && item.data.product_color == data.product_color){
							span_count ++;
						}
					});
					return span_count;
				}}}):_vm._e(),_vm._l((_vm.sizeConfig),function(size,i){return [(_vm.systemConfig.system_size_enable=='ON')?_c('c-table-column',{attrs:{"label":String(i+1),"width":"50"},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [(data[size])?_c('span',[_vm._v(_vm._s(data[size]))]):_c('span',[_vm._v("-")])]}}],null,true)}):_vm._e()]}),_c('c-table-column',{attrs:{"label":"商品总数","name":"product_count","width":"80","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.order_number == data.order_number){
							span_count ++;
						}
					});
					return span_count;
				}}}),_c('c-table-column',{attrs:{"label":"订单金额","name":"order_money","width":"90","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.order_number == data.order_number){
							span_count ++;
						}
					});
					return span_count;
				}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [(data.order_money)?_c('span',[_vm._v("¥"+_vm._s(data.order_money))]):_c('span',[_vm._v("--")])]}}])}),_c('c-table-column',{attrs:{"label":"交货日期","name":"delivery_schedule_date","width":"100","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.order_number == data.order_number){
							span_count ++;
						}
					});
					return span_count;
				}}}),_c('c-table-column',{attrs:{"label":"下单时间","name":"create_date","width":"140","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.order_number == data.order_number){
							span_count ++;
						}
					});
					return span_count;
				}}}),_c('c-table-column',{attrs:{"type":"button","label":"操作","width":"230","row-span":({data},list)=>{
					var span_count = 0;
					list.forEach(item => {
						if(item.data.order_number == data.order_number){
							span_count ++;
						}
					});
					return span_count;
				}},scopedSlots:_vm._u([{key:"default",fn:function({data}){return [(_vm.auth('m_order'))?_c('c-table-button',{on:{"click":function($event){return _vm.navigateTo(`/order/detail/${data.id}`)}}},[_vm._v("查看")]):_vm._e(),(_vm.auth('m_create_order') && data.state==-2)?_c('c-table-button',{on:{"click":function($event){return _vm.navigateTo(`/order/edit/${data.id}`)}}},[_vm._v("编辑")]):_vm._e(),(_vm.auth('m_create_order') && data.state==-2)?_c('c-table-button',{on:{"click":function($event){return _vm.del(data)}}},[_vm._v("删除")]):_vm._e(),(_vm.auth('b_order_transfer') && data.state > 0 && (data.task_state==1 || data.task_state==2))?_c('c-table-button',{on:{"click":function($event){return _vm.navigateTo(`/order/task/${data.id}`)}}},[_vm._v("派工")]):_vm._e(),(_vm.auth('b_order_deliver') && (data.state==1 || data.state==2))?_c('c-table-button',{on:{"click":function($event){return _vm.$refs.deliverDialog.open(data)}}},[_vm._v("发货")]):_vm._e(),(_vm.auth('b_order_deliver') && (data.state==2 || data.state==3))?_c('c-table-button',{on:{"click":function($event){return _vm.$refs.deliverDetailDialog.open(data)}}},[_vm._v("发货明细")]):_vm._e()]}}])})],2),_c('order-deliver',{ref:"deliverDialog",on:{"resolve":function($event){return _vm.shuaxin()}}}),_c('order-deliver-detail',{ref:"deliverDetailDialog"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }